
import alertDialog from "src/components/AlertDialog/AlertDialog.messages";
import blogpost from "src/components/Blogpost/Blogpost.messages";
import breadcrumbs from "src/components/Breadcrumbs/Breadcrumbs.messages";
import bulkPremiumCalcModal from "src/components/BulkPremiumCalcModal/BulkPremiumCalcModal.messages";
import cancelPolicyModal from "src/components/CancelPolicyModal/CancelPolicyModal.messages";
import contact from "src/components/Contact/Contact.messages";
import contextMenu from "src/components/ContextMenu/ContextMenu.messages";
import createClaimBrokerModal from "src/components/CreateClaimModal/CreateClaimBrokerModal.messages";
import createPasswordModal from "src/components/CreatePasswordModal/CreatePasswordModal.messages";
import dashboardContent from "src/components/DashboardContent/DashboardContent.messages";
import dataTable from "src/components/DataTable/DataTable.messages";
import dataTransferLoading from "src/components/DataTransferLoading/DataTransferLoading.messages";
import datePicker from "src/components/DatePicker/DatePicker.messages";
import documentList from "src/components/DocumentList/DocumentList.messages";
import familySubMenu from "src/components/FamilySubMenu/FamilySubMenu.messages";
import footer from "src/components/Footer/Footer.messages";
import forgotPasswordForm from "src/components/ForgotPasswordForm/ForgotPasswordForm.messages";
import headerSubMenu from "src/components/HeaderSubMenu/HeaderSubMenu.messages";
import heroBackLink from "src/components/HeroBackLink/HeroBackLink.messages";
import infoTooltip from "src/components/InfoTooltip/InfoTooltip.messages";
import emailInput from "src/components/Inputs/EmailInput.messages";
import passwordInput from "src/components/Inputs/PasswordInput.messages";
import languageSwitch from "src/components/LanguageSwitch/LanguageSwitch.messages";
import loading from "src/components/Loading/Loading.messages";
import loginForm from "src/components/LoginForm/LoginForm.messages";
import noScript from "src/components/NoScript/NoScript.messages";
import notification from "src/components/Notification/Notification.messages";
import onboardingModal from "src/components/OnboardingModal/OnboardingModal.messages";
import paymentMethodEmilEmbed from "src/components/PaymentMethodEmilEmbed/PaymentMethodEmilEmbed.messages";
import policyCard from "src/components/PolicyCard/PolicyCard.messages";
import policyDetails from "src/components/PolicyDetails/PolicyDetails.messages";
import portalPolicyPricePreview from "src/components/PortalPolicyPricePreview/PortalPolicyPricePreview.messages";
import premiumCalcModal from "src/components/PremiumCalcModal/PremiumCalcModal.messages";
import productGroupPageContent from "src/components/ProductGroupPageContent/ProductGroupPageContent.messages";
import productOverview from "src/components/ProductOverview/ProductOverview.messages";
import scrollButton from "src/components/ScrollButton/ScrollButton.messages";
import setPasswordForm from "src/components/SetPasswordForm/SetPasswordForm.messages";
import spreadsheetUpload from "src/components/SpreadsheetUpload/SpreadsheetUpload.messages";
import statusTag from "src/components/StatusTag/StatusTag.messages";
import textarea from "src/components/Textarea/Textarea.messages";
import updatePaymentIntervalModal from "src/components/UpdatePaymentIntervalModal/UpdatePaymentIntervalModal.messages";
import updatePolicyModal from "src/components/UpdatePolicyModal/UpdatePolicyModal.messages";
import userDataModal from "src/components/UserDataModal/UserDataModal.messages";
import userOverview from "src/components/UserOverview/UserOverview.messages";
import videoPlayer from "src/components/VideoPlayer/VideoPlayer.messages";
import viewClaimModal from "src/components/ViewClaimModal/ViewClaimModal.messages";
import withdrawPolicyModal from "src/components/WithdrawPolicyModal/WithdrawPolicyModal.messages";
import xlsxUpload from "src/components/XlsxUpload/XlsxUpload.messages";
import misc from "src/i18n/misc.messages";
import faqSection from "src/sections/FaqSection/FaqSection.messages";
import navigationSection from "src/sections/NavigationSection/NavigationSection.messages";
import newsletterSection from "src/sections/NewsletterSection/NewsletterSection.messages";
import newsletterSignup from "src/sections/NewsletterSignup/NewsletterSignup.messages";
import offer from "src/sections/Offers/Offer.messages";
import premiumCalcHero from "src/sections/PremiumCalcHero/PremiumCalcHero.messages";
import errorPage from "src/templates/ErrorPage.messages";

export const defaultMessages = {
	...alertDialog,
	...blogpost,
	...breadcrumbs,
	...bulkPremiumCalcModal,
	...cancelPolicyModal,
	...contact,
	...contextMenu,
	...createClaimBrokerModal,
	...createPasswordModal,
	...dashboardContent,
	...dataTable,
	...dataTransferLoading,
	...datePicker,
	...documentList,
	...familySubMenu,
	...footer,
	...forgotPasswordForm,
	...headerSubMenu,
	...heroBackLink,
	...infoTooltip,
	...emailInput,
	...passwordInput,
	...languageSwitch,
	...loading,
	...loginForm,
	...noScript,
	...notification,
	...onboardingModal,
	...paymentMethodEmilEmbed,
	...policyCard,
	...policyDetails,
	...portalPolicyPricePreview,
	...premiumCalcModal,
	...productGroupPageContent,
	...productOverview,
	...scrollButton,
	...setPasswordForm,
	...spreadsheetUpload,
	...statusTag,
	...textarea,
	...updatePaymentIntervalModal,
	...updatePolicyModal,
	...userDataModal,
	...userOverview,
	...videoPlayer,
	...viewClaimModal,
	...withdrawPolicyModal,
	...xlsxUpload,
	...misc,
	...faqSection,
	...navigationSection,
	...newsletterSection,
	...newsletterSignup,
	...offer,
	...premiumCalcHero,
	...errorPage,
}
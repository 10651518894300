const zodErrors = {
	"zod.confirmationRequired": "Bitte bestätigen",
	"zod.date": "Ungültiges Datum",
	"zod.number": "Zahl erwartet",
	"zod.default": "Hier stimmt etwas nicht",
	"zod.email": "Keine gültige E-Mail-Adresse",
	"zod.iban": "Keine gültige IBAN",
	"zod.invalid_string": "Ungültiger Wert",
	"zod.invalid_type": "Ungültiger Wert",
	"zod.missing": "Hier stimmt etwas nicht!",
	"zod.password": "Kein gültiges Kennwort",
	"zod.postCode": "Ungültige PLZ",
	"zod.required": "Pflichtfeld",
	"zod.too_big": "Zu viele Zeichen",
};

export default {
	...zodErrors,
	backToDashboard: "zurück zur Übersicht",
	"customer.altText": "Ein Photo von {name}",
	comingSoon: "Demnächst verfügbar",
	datePlaceholder: "TT.MM.JJJJ",
	day: "Tag",
	dd: "TT",
	edit: "Bearbeiten",
	editUserData: "Stammdaten ändern",
	female: "Frau",
	jumpToNext: "Zum nächsten Modul springen",
	jumpToTop: "Nach oben springen",
	login: "Anmelden",
	logout: "Log Out",
	male: "Herr",
	menu: "Menu",
	mm: "MM",
	month: "Monat",
	pageContent: "Seiteninhalt",
	password: "Passwort",
	"policy.detail": "Ihre Versicherungen im Detail",
	"policy.title": "Details zu Ihrer Versicherung",
	seeMore: "Mehr sehen +{count}",
	seeLess: "Weniger sehen",
	sent: "Gesendet",
	skipLink: "Zum Inhalt springen",
	"toast.authTimeout": "Sie wurden zu Ihrer Sicherheit ausgeloggt",
	"toast.formSubmitError":
		"Wir konnten dieses Formular nicht absenden. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
	"toast.genericError": "Unerwarteter Fehler: {error}",
	unspecified: "Ohne",
	year: "Jahr",
	yyyy: "JJJJ",
} as const;
